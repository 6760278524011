import { createContext, ReactNode, useContext } from "react"
import { prisma } from "~/utils/prisma.server"

const getFeatureFlags = () => prisma.featureFlags.findMany()

type FeatureFlagsContext = Awaited<ReturnType<typeof getFeatureFlags>>

const context = createContext<FeatureFlagsContext>([])

interface Props {
  flags: FeatureFlagsContext
  children: ReactNode
}

export const FeatureFlagsProvider = ({ children, flags }: Props) => {
  return <context.Provider value={flags}>{children}</context.Provider>
}

export const useFeatureFlag = (featureFlag: string) => {
  const featureFlags = useContext(context)
  return (
    featureFlags.find((flag) => flag.flag === featureFlag)?.enabled ?? false
  )
}
